export default [
  {
    width: '40px',
    value: 'edit',
    align: 'center',
    colModifiers:[
      { type: 'edit' }
    ],
    editPath: 'warehouses/edit/'
  },
  { text: 'Название', value: 'name' },
  { text: 'Адрес', value: 'address' },
  { text: 'Объем склада', value: 'size' },
  { text: 'Координаты', value: 'coordinates' },
  { text: 'Ответственный сотрудник', value: 'manager', width: '110px' },
  { text: 'Контакты', value: 'contact' },
  {
    text: 'Примечание',
    value: 'note',
    width: '120px',
    colModifiers: [
      { type: 'icon', value: 'mdi-alert-circle',  isRequired: true },
      { type: 'tooltip' },
      { type: 'color', value: 'error' },
      { type: 'align', value: 'center' }
    ]
  }
]
