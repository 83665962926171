<template>
  <div class="rcc-table-list">
    <div class="rcc-table-list__button-wrapper" v-if="editAccess">
      <rcc-button @click="$router.push({ name: 'warehouses-new' })">
        Добавить склад
      </rcc-button>
    </div>

    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="1"
        :itemsPerPage="50"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isShowFilters="false"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      />
    </div>
  </div>
</template>

<script>
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import WarehousesApi from '@/api/warehouses'
import headerData from './header'

import RccButton from 'Components/controls/rcc-button'
import RccTable from 'Components/table/rcc-table'

export default {
  mixins: [Page, TableList, WarehousesApi],

  components: {
    RccTable,
    RccButton
  },

  computed: {
    headerData() {
      return headerData
    }
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.getWarehouses({ page: this.page, ...filters  })
        .then(({ pages, items }) => {
          if (!pages) {
            this.resetTableData()
            return
          }

          this.pagesCount = pages

          this.rows = items.map(item => {
            return { ...item, coordinates: `${item.lon}, ${item.lat}` }
          })
        })
        .finally(() => { this.isDataLoading = false })
    }
  }
}
</script>
